import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

const schema = yup.object().shape({
  id: yup.number().nullable(),
  title: yup.string().required("Title is required"),
  start_time: yup.date().required("Start date is required"),
  end_time: yup.date().required("End date is required"),
  description: yup.string().nullable(),
  link: yup
    .string()
    .url("Link must be a valid full URL (e.g. https://example.com)")
    .nullable(),
  link_name: yup.string().nullable(),
  published: yup.boolean(),
});

const defaultValues = {
  start_time: null,
  end_time: null,
  title: "",
  description: "",
  link: "",
  link_name: "",
  published: false,
};

const AgendaFormProvider = ({ children, agenda = null }) => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    const extractDefaultValues = (agenda) => {
      if (!agenda) return defaultValues;

      return {
        id: agenda.id || null,
        start_time: new Date(agenda.start_time) || null,
        end_time: new Date(agenda.end_time) || null,
        title: agenda.title || "",
        description: agenda.description || "",
        link: agenda.link || "",
        link_name: agenda.link_name || "",
        published: agenda.published || false,
      };
    };

    methods.reset(extractDefaultValues(agenda));
  }, [agenda]);

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default AgendaFormProvider;
