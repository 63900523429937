import React from "react";
import { useOutletContext } from "react-router-dom";
import { Button } from "rsuite";

const BookingModalButtons = ({
  currentStep,
  setCurrentStep,
  handleSubmit,
  handleClose,
  trigger,
  insufficientCredit,
  isMentor,
  isSubmitting,
  disable = false,
}) => {
  const triggerValidateFormStep = async () => {
    let valid = await trigger();
    if (valid) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handleBackStep = (step) => {
    if (step === 0) {
      handleClose();
    }

    if (step > 0 && step <= 3) {
      setCurrentStep((prevStep) => prevStep - 1);
    }

    return;
  };

  const handleForwardStep = (step) => {
    if (step === 2) {
      return triggerValidateFormStep();
    }

    if (step === 3) {
      // Note: insufficientCredit redirect is handled by
      // pre_validate_booking in bookings_controller.rb
      handleSubmit();
    }

    if (step < 3) {
      setCurrentStep((prevStep) => prevStep + 1);
    }

    return;
  };

  return (
    <>
      <Button
        onClick={() => handleBackStep(currentStep)}
        appearance="ghost"
        disabled={disable}
      >
        {currentStep == 0 ? "Cancel" : "Back"}
      </Button>

      <Button
        onClick={() => handleForwardStep(currentStep)}
        appearance="primary"
        loading={isSubmitting}
        disabled={disable}
      >
        {currentStep === 3 && insufficientCredit && !isMentor
          ? "Top up Credit"
          : currentStep === 3
          ? "Request"
          : "Next"}
      </Button>
    </>
  );
};

export default BookingModalButtons;
