import { getGoals } from 'api/shared/sharedApi';
import Toaster from 'components/shared/Toaster';
import { useState, useEffect } from 'react';

const STORAGE_KEY = 'goalsData';
const EXPIRY_TIME = 60 * 60 * 1000; // 1 hour in milliseconds

const useGoals = () => {
  const [goals, setGoals] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchGoals = async () => {
    try {
      const { data } = await getGoals();
      
      // Store both the goals data and the timestamp
      const timestamp = Date.now();    
      localStorage.setItem(STORAGE_KEY, JSON.stringify({ data, timestamp }));
      setGoals(data);
    } catch (err) {
      Toaster({
        type: 'error',
        msg: "Error fetching goals",
      })
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedGoalData = localStorage.getItem(STORAGE_KEY);

    if (storedGoalData) {
      const { data, timestamp } = JSON.parse(storedGoalData);
      const currentTime = Date.now();

      // Check if data is still valid (within 1 hour)
      if (currentTime - timestamp < EXPIRY_TIME) {
        setGoals(data);
        setLoading(false);
        return;
      }
    }

    fetchGoals();
  }, []);

  return { goals, loading };
};

export default useGoals;
