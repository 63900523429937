import React from "react";

const formatToRm = (num: number) => {
  return new Intl.NumberFormat("en-MY", {
    style: "currency",
    currency: "MYR",
  }).format(num);
};

export const PriceSummary = ({ price, discountedAmount, transactionFee }) => {
  return (
    <>
      <div className="tw-flex tw-justify-between">
        <span>Price</span>
        <span>{formatToRm(price)}</span>
      </div>
      <div className="tw-flex tw-justify-between">
        <span>Tax</span>
        <span>{formatToRm(0)}</span>
      </div>
      <div className="tw-flex tw-justify-between">
        <span>Discount</span>
        <span>{formatToRm(discountedAmount)}</span>
      </div>
      <div className="tw-flex tw-justify-between">
        <span>Transaction Fee</span>
        <span>{formatToRm(transactionFee)}</span>
      </div>
    </>
  );
};

export const PriceSubTotal = ({ subTotal }) => (
  <div className="tw-flex tw-justify-between">
    <p>
      <b>Total</b>
    </p>
    <b>
      <b>{formatToRm(subTotal)}</b>
    </b>
  </div>
);
