import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useResource } from "../../context/resourceContext";

interface FormData {
  title: string;
  category: string;
  user_type: string;
  description: string | null;
  url: string | null;
  file: File | null;
}

const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
  user_type: yup.string().required("Role is required"),
  description: yup.string().nullable(),
  url: yup
    .string()
    .url(
      "Please enter a valid URL, including the full address (e.g. https://example.com)"
    )
    .nullable(),
  file: yup.mixed().nullable(),
});

const defaultValues = {
  url: "",
  title: "",
  description: "",
  user_type: "All",
};

const ResourceFormProvider = ({ children }) => {
  const { resource } = useResource();

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    const extractDefaultValues = (resource) => {
      if (!resource) return defaultValues;

      return {
        title: resource?.title || "",
        category: resource?.category || "",
        user_type: resource?.user_type || "",
        description: resource?.description || null,
        url: resource?.url || null,
        file: resource?.file || null,
      };
    };

    methods.reset(extractDefaultValues(resource));
  }, [resource]);

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default ResourceFormProvider;
