import { deleteAgenda } from "api/portal/programApi";
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "components/shared/modal/BaseModal";
import Toaster from "components/shared/Toaster";
import React, { useState } from "react";
import { Button, Modal } from "rsuite";

const DeleteConfirmationModal = ({
  agenda,
  openModal,
  setOpenModal,
  handleDeleteAgenda,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async (agendaId) => {
    setLoading(true);
    try {
      await handleDeleteAgenda(agendaId);
      setOpenModal(false);
      setLoading(false);
      Toaster({
        type: "success",
        msg: "Successfully deleted.",
      });
    } catch (error) {
      setLoading(false);
      setOpenModal(false);
      Toaster({
        type: "error",
        msg: "Oops, something went wrong!",
      });
    }
  };
  return (
    <Modal open={openModal} size={"xs"} onClose={() => setOpenModal(false)}>
      <ModalHeader>Delete Agenda</ModalHeader>
      <ModalBody>Are you sure you want to delete this agenda?</ModalBody>
      <ModalFooter className="tw-flex tw-justify-end tw-pt-4">
        <Button appearance="ghost" onClick={() => setOpenModal(false)}>
          Cancel
        </Button>
        <Button
          appearance="primary"
          loading={loading}
          onClick={() => handleDelete(agenda.id)}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteConfirmationModal;
