import React, { createContext, useContext, useEffect, useState } from "react";
import { useEntities } from "../hooks/useEntities";
import { usePortal } from "contexts/portal";

const ResourceContext = createContext(null);

export const ResourceProvider = ({ children }) => {
  const [resource, setResource] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isFile, setIsFile] = useState(false);

  const { program } = usePortal();

  const {
    fetchEntities,
    resources,
    loading,
    createEntityData,
    deleteEntityData,
    updateEntityData,
  } = useEntities();

  useEffect(() => {
    fetchEntities();
  }, [program]);

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsFile(false);
    setResource(null);
  };

  const contextValues = {
    resources,
    loading,
    resource,
    setResource,
    openModal,
    setOpenModal,
    handleCloseModal,
    isFile,
    setIsFile,
    fetchEntities,
    createEntityData,
    deleteEntityData,
    updateEntityData,
  };

  return (
    <ResourceContext.Provider value={contextValues}>
      {children}
    </ResourceContext.Provider>
  );
};

export const useResource = () => useContext(ResourceContext);
