import React from "react";
import { Form, Panel } from "rsuite";
import translateText from "util/translateText";

import {
  FormInputText,
  FormTagPicker,
} from "components/form/default/FormInputs";

const DetailsStep = ({ convertText, control, errors, goals }) => {
  return (
    <>
      <div className="tw-text-left">
        <Form fluid className="tw-pt-5">
          <Form.Group>
            <FormInputText
              name="topic"
              control={control}
              errors={errors}
              as="textarea"
              rows={12}
              label="What do you need help with?"
              placeholder="Share key details about your background, career goals, challenges you're facing, and what you'd like to achieve from this session. The more specific, the better!"
            />
          </Form.Group>
          <Form.Group>
            <FormTagPicker
              name="learning_objectives"
              control={control}
              label="Topic of discussion"
              placeholder={translateText(
                convertText,
                "Select up to a maximum of 3"
              )}
              valueKey={"name"}
              errors={errors}
              data={goals}
              placement="autoVertical"
              size="lg"
              cleanable={false}
              block
            />
          </Form.Group>
        </Form>
      </div>
    </>
  );
};

export default DetailsStep;
