import React from "react";
import { Divider, Modal } from "rsuite";

export const ModalHeader = ({ children, ...props }) => {
  return (
    <>
      <Modal.Header {...props}>
        <Modal.Title className="tw-font-medium">{children}</Modal.Title>
      </Modal.Header>
      <Divider className="tw-absolute sm:tw-relative tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-200" />
    </>
  );
};

export const ModalBody = ({ children, ...props }) => {
  return (
    <Modal.Body className="tw-py-5" {...props}>
      {children}
    </Modal.Body>
  );
};

export const ModalFooter = ({ children, ...props }) => {
  return (
    <>
      <Divider className="tw-absolute sm:tw-relative tw-w-full tw-left-0 tw-m-0 tw-bg-gray-200" />
      <Modal.Footer {...props}>{children}</Modal.Footer>
    </>
  );
};
