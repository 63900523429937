import { createApiInstance } from "api/config";

const api = createApiInstance('portal')


// Program
// Grape: /app/api/portal/v1/programs.rb
export const checkProgramName = async (name: string, enterpriseId: number) => {
  return await api.get(`/programs/check_name`, {
    params: { name: name, enterprise_id: enterpriseId },
  });
};

export const getProgram = async (queryParams: string | number) => {
  return await api.get(`/programs`, {
    params: { id: queryParams },
  });
}

export const postProgram = async (data) => {
  return await api.post(`/programs`, data);
}

export const getEnterprise = async (id: number) => {
  return await api.get(`/enterprises/${id}`);
}

// Agendas
// Grape: /app/api/portal/v1/agendas.rb
export const getAllAgendas = async (programId: number) => {
  return await api.get(`/agendas`, {
    params: { program_id: programId },
  });
}

export const getAgenda = async (agendaId: number) => {
  return await api.get(`/agendas/${agendaId}`);
}

export const createAgenda = async (data) => {
  return await api.post(`/agendas`, data);
}

export const updateAgenda = async (agendaId: number, data) => {
  return await api.patch(`/agendas/${agendaId}`, data);
}

export const publishAgenda = async (agendaId: number) => {
  return await api.patch(`/agendas/${agendaId}/publish`);
}

export const deleteAgenda = async (agendaId: number) => {
  return await api.delete(`/agendas/${agendaId}`);
}

// Entities (Resources)
// Grape: /app/api/portal/v1/entities.rb
export const getAllEntities = async (programId: number) => {
  return await api.get(`/entities`, {
    params: { program_id: programId },
  });
}

export const getEntity = async (entityId: number) => {
  return await api.get(`/entities/${entityId}`);
}

export const createEntity = async (data) => {
  return await api.post(`/entities`, data);
}

export const updateEntity = async (entityId: number, data) => {
  return await api.patch(`/entities/${entityId}`, data);
}

export const deleteEntity = async (entityId: number) => {
  return await api.delete(`/entities/${entityId}`);
}