import React from "react";
import { Button, Modal } from "rsuite";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "components/shared/modal/BaseModal";
import { useFormContext } from "react-hook-form";
import ResourcesForm from "./forms/ResourcesForm";
import { useResource } from "./context/resourceContext";
import { usePortal } from "contexts/portal";

const ResourceEditorModal = () => {
  const { handleSubmit, reset } = useFormContext();

  const {
    loading,
    resource,
    openModal,
    isFile,
    createEntityData,
    updateEntityData,
    handleCloseModal,
  } = useResource();

  const { program } = usePortal();

  const onSubmit = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined && data[key] !== null) {
        formData.append(key, data[key]);
      }
    });
    formData.append("category", isFile ? "file" : "link");
    formData.append("program_id", program.id);

    try {
      resource
        ? await updateEntityData(resource.id, formData)
        : await createEntityData(formData);
      onCloseModal();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const onCloseModal = () => {
    handleCloseModal();
    reset();
  };

  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <ModalHeader>
        {resource ? "Edit" : "Add"} {isFile ? "File" : "Link"}
      </ModalHeader>
      <ModalBody>
        <ResourcesForm isFile={isFile} />
      </ModalBody>
      <ModalFooter className="tw-flex tw-justify-end tw-pt-4">
        <Button appearance="ghost" onClick={onCloseModal}>
          Cancel
        </Button>
        <Button
          appearance="primary"
          loading={loading}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ResourceEditorModal;
