// React
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import {
  IMentorContext,
  PlatformType,
} from "../../../../../contexts/mentor_profile";

import { withUser } from "../../../../../contexts/user";
import translateText from "../../../../../util/translateText";

// UI
import { Modal } from "rsuite";
import DateStep from "../shared/booking_modal/DateStep";
import TimeStep from "../shared/booking_modal/TimeStep";
import DetailsStep from "../shared/booking_modal/DetailsStep";
import SummaryStep from "../shared/booking_modal/SummaryStep";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "components/shared/modal/BaseModal";
import StepsList from "../shared/booking_modal/StepsList";
import BookingModalButtons from "../shared/booking_modal/BookingModalButtons";

// 3rd Party
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { createApiInstance } from "api/config";
import Toaster from "components/shared/Toaster";
import useGoals from "hooks/useGoals";
import getWindowSize from "util/getWindowSize";

const api = createApiInstance("rails");

type BookingInput = {
  topic: string;
  learning_objectives: string[];
};

const schema = yup.object().shape({
  learning_objectives: yup
    .array()
    .max(3, "You can only select up to 3 topics")
    .min(1, "You need at least 1 topic")
    .required("This selection is required")
    .nullable(),
  topic: yup.string().required("Your key details is required"),
});

const BookingModal = ({
  user,
  open,
  handleClose,
  initialDate = undefined,
  initialTime = undefined,
  daySize = 50,
  convertText,
}) => {
  const { mentor, type, program } = useOutletContext<IMentorContext>();
  const { available_dates, timeslots } = mentor as any;
  const { goals } = useGoals();

  const [date, setDate] = useState(initialDate);
  const [time, setTime] = useState(initialTime);
  const [focused, setFocused] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userCredit = user.balance / 10;
  const insufficientCredit = userCredit < 1;
  const [currentStep, setCurrentStep] = useState(date && time ? 3 : 0);
  const isMentor = user.is_fl_mentor || false;

  const isMobile = getWindowSize() === "small";

  useEffect(() => {
    if (initialDate && initialTime) {
      setDate(initialDate);
      setTime(initialTime);
      setCurrentStep(2);
    }
  }, [initialDate, initialTime]);

  useEffect(() => {
    if (type === PlatformType.Portal && currentStep === 0) {
      setCurrentStep(1);
    }
  }, [type, currentStep]);

  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors },
    getValues,
  } = useForm<BookingInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      topic: "",
      learning_objectives: [],
    },
  });

  // TODO: Suggest to move to form submission to a custom hook in future
  const getBookingParams = (data) => {
    return {
      date: date.format("DD/MM/YYYY"),
      time: time,
      booking: {
        //@ts-ignore
        program_id: program?.id || null,
        //@ts-ignore
        mentor_id: mentor?.id,
        mentee_id: user.id,
        status: "pending",
        session_method: "appear_in",
        topic: data.topic,
        service_id: "2 3 27 28 29",
        booking_links_attributes: {
          "0": { internal_link: "", _destroy: "1" },
        },
        booking_attachments_attributes: { "0": { _destroy: "1" } },
      },
      objective: data.learning_objectives,
      react_form: true,
    };
  };

  // Submission of form to rails /main/bookings/bookings_controller, :create
  const submitUrl =
    type === PlatformType.FutureLab
      ? "/main/bookings"
      : "/bookings/booking_submission";

  const onSubmit = async (data) => {
    const bookingParams = getBookingParams(data);
    setIsSubmitting(true);

    const token = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    api.defaults.headers.common["X-CSRF-TOKEN"] = token;

    try {
      const response = await api.post(submitUrl, bookingParams, {
        headers: {
          "Content-Type": "application/json",
          Accept: "text/javascript",
        },
        withCredentials: true,
        maxRedirects: 0,
      });

      if (response.status === 200) {
        Toaster({
          type: "success",
          msg: "Booking created successfully",
        });

        window.location.href =
          type === PlatformType.FutureLab ? "/booking-complete" : "/bookings";
      }

      handleClose();
    } catch (err) {
      const { response } = err;

      if (response.status === 302) {
        const redirectUrl = response.data.url;

        // Perform the client-side redirect
        window.location.href = redirectUrl;
        setIsSubmitting(true);
        return;
      }

      Toaster({
        type: "error",
        msg: "Oops, something went wrong.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={isMobile ? "" : "tw-w-[500px]"}
      backdrop="static"
      size={isMobile ? "full" : ""}
    >
      <ModalHeader>
        {translateText(convertText, "Book Session")}{" "}
        {/* {mentor.firstname && `with ${mentor.firstname}`} */}
      </ModalHeader>
      <ModalBody>
        <StepsList convertText={convertText} currentStep={currentStep} />
        <div className="tw-my-4">
          {currentStep === 0 && (
            <DateStep
              date={date}
              setDate={setDate}
              focused={focused}
              setFocused={setFocused}
              available_dates={available_dates}
              daySize={daySize}
            />
          )}
          {currentStep === 1 && (
            <TimeStep
              date={date}
              time={time}
              setTime={setTime}
              timeslots={timeslots}
            />
          )}
          {currentStep === 2 && (
            <DetailsStep
              convertText={convertText}
              control={control}
              errors={errors}
              goals={goals}
            />
          )}
          {currentStep === 3 && (
            <SummaryStep
              getValues={getValues}
              mentor={mentor}
              goals={goals}
              date={date}
              time={time}
            />
          )}
        </div>
      </ModalBody>
      <ModalFooter className="tw-pt-5">
        {insufficientCredit && currentStep === 3 && !isMentor && (
          <div className="tw-flex tw-justify-end tw-mb-2">
            <span className="">Ready to make a booking?</span>
          </div>
        )}
        <BookingModalButtons
          trigger={trigger}
          handleSubmit={handleSubmit(onSubmit)}
          handleClose={handleClose}
          currentStep={currentStep}
          isMentor={isMentor}
          setCurrentStep={setCurrentStep}
          isSubmitting={isSubmitting}
          insufficientCredit={insufficientCredit}
        />
      </ModalFooter>
    </Modal>
  );
};

export default withUser(BookingModal);
