import React, { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { usePortal } from "contexts/portal";
import { Button, Form } from "rsuite";
import { FormInputText } from "components/form/default/FormInputs";
import Toaster from "components/shared/Toaster";
import { postProgram } from "api/portal/programApi";

const schema = yup.object().shape({
  program: yup.object().shape({
    dashboard_alert: yup
      .string()
      .max(300, "Exceeded max characters (max 300)")
      .nullable(),
  }),
});

const DashboardNotificationsForm = () => {
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { program: { dashboard_alert: "" } },
  });
  const [loading, setLoading] = useState(false);
  const { program, setProgram } = usePortal();

  useEffect(() => {
    if (!program) return;
    reset({ program: { dashboard_alert: program?.dashboard_alert } });
  }, [program]);

  const onSubmit = async (data) => {
    data.program_id = program?.id;
    setLoading(true);

    try {
      const response = await postProgram(data);
      setLoading(false);
      setProgram(response.data);
      Toaster({
        type: "success",
        msg: "Successfully saved.",
      });
    } catch (error) {
      Toaster({
        type: "error",
        msg: "Failed to save.",
      });
      console.log(error);
    }
  };

  return (
    <Form>
      <FormInputText
        name="program.dashboard_alert"
        control={control}
        max_length={300}
        placeholder={"Enter a message"}
        errors={errors}
        as="textarea"
        rows={5}
      />

      <div className="tw-flex tw-justify-end tw-mt-4">
        <Button
          loading={loading}
          appearance="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

export default DashboardNotificationsForm;
