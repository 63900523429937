import React from "react";
import { MdOutlineCalendarMonth, MdOutlineSchedule } from "react-icons/md";
import { Avatar, Divider } from "rsuite";

const BookingMentorPreview = ({ mentor, bookingDate, bookingTime }) => {
  return (
    <>
      <p className="tw-font-bold">Your session with</p>

      <div className="tw-flex tw-mb-4 tw-gap-2">
        <Avatar src={mentor.profile_photo} alt={mentor.name.charAt(0)} />
        <div className="tw-flex tw-flex-col">
          <span className="tw-font-bold">{mentor.name}</span>
          <span className="tw-text-base tw-flex tw-items-center">
            <MdOutlineCalendarMonth size={16} className="tw-mr-1" />
            {bookingDate?.format("dddd, DD MMMM YYYY")}
          </span>
          <span className="tw-text-base tw-flex tw-items-center">
            <MdOutlineSchedule size={16} className="tw-mr-1" />
            {bookingTime}
          </span>
        </div>
      </div>
      <Divider />
    </>
  );
};

export default BookingMentorPreview;
