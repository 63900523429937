// React
import React from "react";

// FutureLab
import translateText from "../../../../../util/translateText";

// UI
import { Col, Grid, Message, Row, toaster } from "rsuite";

// 3rd Party
import moment from "moment";
import { DateTime } from "luxon";

const QuickBookingCardPreview = ({
  mentor,
  setInitialDate,
  setInitialTime,
  currentUser,
  convertText,
  handleOpen,
}) => {
  const { timeslots, available_dates: availableDates } = mentor;

  return (
    <>
      <p className="tw-text-center sm:tw-text-sm tw-font-medium montserrat">
        {translateText(convertText, "My next upcoming availability")}
      </p>
      <Grid fluid>
        <Row gutter={8}>
          {availableDates?.map(
            (date, idx) =>
              idx < 3 && (
                <Col xs={8} key={date}>
                  <a
                    onClick={() => {
                      if (currentUser) {
                        setInitialDate(moment(date, "DD/MM/YYYY"));
                        setInitialTime(timeslots[date][0]);
                        handleOpen();
                      } else {
                        toaster.push(
                          <Message showIcon type="info">
                            {translateText(convertText, "Please sign in")}
                          </Message>
                        );
                      }
                    }}
                  >
                    <div className="tw-border tw-border-solid tw-border-gray-300 tw-rounded-xl tw-p-2">
                      <p className="tw-text-sm tw-font-bold tw-text-center tw-mb-0 tw-uppercase montserrat">
                        {DateTime.fromFormat(date, "dd/MM/yyyy").toFormat(
                          "MMM"
                        )}
                      </p>
                      <p className="tw-text-2xl tw-font-bold tw-text-center tw-mb-0 montserrat">
                        {DateTime.fromFormat(date, "dd/MM/yyyy").toFormat("dd")}
                      </p>
                      <p className="tw-text-sm tw-text-center tw-mb-0 montserrat">
                        {timeslots[date][0]}
                      </p>
                    </div>
                  </a>
                </Col>
              )
          )}
        </Row>
      </Grid>
    </>
  );
};

export default QuickBookingCardPreview;
