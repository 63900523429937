import React, { useState } from "react";

import { createAgenda, updateAgenda } from "api/portal/programApi";
import Toaster from "components/shared/Toaster";
import { useFormContext } from "react-hook-form";
import { Button } from "rsuite";
import { usePortal } from "contexts/portal";

const AgendaFormSubmitButton = ({
  setOpenModal,
  handleCreateAgenda,
  handleUpdateAgenda,
  agenda = null,
}) => {
  const { handleSubmit } = useFormContext();
  const { program } = usePortal();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    data.program_id = program?.id;
    setLoading(true);
    try {
      if (agenda) {
        await handleUpdateAgenda(data);
      } else {
        await handleCreateAgenda(data);
      }
    } finally {
      setLoading(false);
      setOpenModal(false);
    }
  };

  return (
    <Button
      appearance="primary"
      loading={loading}
      onClick={handleSubmit(onSubmit)}
    >
      Save
    </Button>
  );
};

export default AgendaFormSubmitButton;
