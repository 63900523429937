import React from "react";
import { MdOutlineCloudUpload } from "react-icons/md";
import { Button } from "rsuite";
import { useSetupVariable } from "../../../../contexts/portal/setup/setupVariables";
import { useNavigate } from "react-router-dom";

const BottomNavCTA = ({ handleSubmit, loading }) => {
  // FUT-1506: Initial PortalSetup was design to be multistep form.
  // Check File History for earlier implementation as reference.

  const navigate = useNavigate();

  return (
    <div
      className="tw-absolute tw-w-full tw-flex tw-justify-end tw-py-4"
      style={{ borderTop: "1px solid #cacaca" }}
    >
      <Button
        appearance="ghost"
        className="tw-mr-2"
        onClick={() => navigate("/dashboard")}
      >
        Cancel
      </Button>

      <Button loading={loading} appearance="primary" onClick={handleSubmit}>
        Create
      </Button>
    </div>
  );
};

export default BottomNavCTA;
