import React from "react";
import { Button, Col, Divider, FlexboxGrid } from "rsuite";
import PortalAgendaModal from "../components/agenda/PortalAgendaModal";
import AgendaList from "../components/agenda/AgendaList";
import { useAgenda } from "../components/agenda/hooks/useAgenda";

const PortalAgenda = () => {
  const {
    agendas,
    loading,
    openModal,
    selectedAgenda,
    handleEdit,
    handleOpenModal,
    handleCloseModal,
    handleCreateAgenda,
    handleDeleteAgenda,
    handleUpdateAgenda,
    handlePublishAgenda,
  } = useAgenda();

  return (
    <>
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item as={Col} colspan={24} md={18} lg={12}>
          <h1 className="tw-text-xl">Agenda</h1>
          <p className="montserrat">
            Manage the flow of your program’s structured outline.
          </p>
          <Button appearance="primary" onClick={handleOpenModal}>
            Add New
          </Button>
          <Divider />
          <AgendaList
            agendas={agendas}
            loading={loading}
            onEdit={handleEdit}
            handleDeleteAgenda={handleDeleteAgenda}
            handlePublishAgenda={handlePublishAgenda}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <PortalAgendaModal
        openModal={openModal}
        setOpenModal={handleCloseModal}
        handleUpdateAgenda={handleUpdateAgenda}
        handleCreateAgenda={handleCreateAgenda}
        agenda={selectedAgenda}
      />
    </>
  );
};

export default PortalAgenda;
