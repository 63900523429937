// React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// FutureLab
import { Agenda } from "../types";
import { formatDateTime, isOnSameDay } from "util/dateTimeFormatter";

// UI
import { Button, Nav } from "rsuite";
import {
  FaRegFileLines,
  FaPaperclip,
  FaRegCalendarCheck,
  FaRegClock,
} from "react-icons/fa6";


export const DashboardAgenda = ({ agendas }) => {
  const [activeKey, setActiveKey] = useState("1");
  const navigate = useNavigate();

  let dataDisplay = activeKey === "1" ? agendas.upcoming : agendas.past;

  return (
    <div className="tw-mt-8">
      <div className="tw-flex tw-justify-between tw-mb-4">
        <h1 className="tw-text-xl ">Programme Structure</h1>
        <Button
          appearance="primary"
          onClick={() => navigate("./agenda")}
        >
          Create Agenda
        </Button>
      </div>
      <div className="tw-py-4 tw-px-8 tw-border-solid tw-border tw-border-slate-200 tw-rounded-lg tw-max-h-[600px] tw-overflow-y-auto no-scrollbar">
        <Nav
          appearance="subtle"
          activeKey={activeKey}
          onSelect={setActiveKey}
          className="tw-mb-3 tw-inline-flex"
        >
          <Nav.Item eventKey="1" className="">
            Upcoming
          </Nav.Item>
          <Nav.Item eventKey="2" className="">
            Past
          </Nav.Item>
        </Nav>
        {dataDisplay.length > 0 ? (
          <>
            {dataDisplay.map((agenda: Agenda) => {
              return (
                <div
                  key={agenda.id}
                  className="tw-p-6 tw-mb-4 tw-border-solid tw-border tw-border-slate-200 tw-rounded-lg"
                >
                  <h6>{agenda.title}</h6>
                  <p className="montserrat">{agenda.description}</p>
                  <div className="tw-text-sm">
                    <span className="tw-gap-2 tw-pr-6 tw-inline-flex tw-items-center">
                      <FaRegCalendarCheck size={16} />
                      {isOnSameDay(agenda.start_time, agenda.end_time)
                        ? formatDateTime(agenda.start_time, "Do MMMM YYYY")
                        : `${formatDateTime(agenda.start_time,"Do MMMM YYYY")} - ${formatDateTime(agenda.end_time,"Do MMMM YYYY")}`}
                    </span>
                    <span className="tw-gap-2 tw-inline-flex tw-items-center">
                      <FaRegClock size={16} />
                      {`${formatDateTime(agenda.start_time,"HH:mm A")} - ${formatDateTime(agenda.end_time, "HH:mm A")}`}
                    </span>
                  </div>
                  {agenda.link && (
                    <Button
                      appearance="link"
                      href={`${agenda.link}`}
                      target="_blank"
                      startIcon={<FaPaperclip size={16} />}
                      className="pl-0"
                    >
                      {agenda.link_name}
                    </Button>
                  )}
                </div>
              );
            })}
          </>
        ) : (
          <EmptyState activeKey={activeKey} />
        )}
      </div>
    </div>
  );
};

const EmptyState = ({ activeKey }) => {
  const description = {
    "1": {
      empty_text: "You have no upcoming program agenda",
    },
    "2": {
      empty_text: "You have no past program agenda",
    },
  };

  return (
    <div className="tw-text-center tw-mt-3">
      <div>
        <FaRegFileLines size={72} color="#C4C4C4" />
      </div>
      <div className="tw-mt-4 tw-mb-6">{description[activeKey].empty_text}</div>
    </div>
  );
};
