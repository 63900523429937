import  { useMemo, useState } from 'react'
import { createEntity, deleteEntity, getAllEntities, updateEntity } from 'api/portal/programApi';
import { usePortal } from 'contexts/portal';
import Toaster from 'components/shared/Toaster';

// Resource is currently defined as Entity in Programs.
// Refer to Entity.rb for more details

export const useEntities = () => {
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(false);

  const memoizedResources = useMemo(() => resources, [resources]);

  const { program } = usePortal();

  const fetchEntities = async () => {
    if (!program?.id) {
      Toaster({
        type: 'error',
        msg: 'Program ID is missing. Cannot fetch resources.',
      });
      return;
    }

    setLoading(true);
    try {
      const {data} = await getAllEntities(program.id);
      setResources(data);  // Safely set the resources
    } catch (error) {
      console.error('Error fetching entities:', error);  // Log the error for debugging
      Toaster({
        type: 'error',
        msg: 'Failed to fetch resources.',
      });
    } finally {
      setLoading(false);
    }
  };

  const updateEntityData = async (entityId: number, data) => {
    setLoading(true);
    try {
      await updateEntity(entityId, data);
      await fetchEntities();
      setLoading(false);
      Toaster({
        type: "success",
        msg: "Successfully updated.",
      })
    } catch (error) {
      setLoading(false);
      Toaster({
        type: "error",
        msg: "Failed to update.",
      })
   }
  }

  const createEntityData = async (data) => {
    if (!program) return false
    data.program_id = program?.id
    setLoading(true);
    try {
      await createEntity(data);
      await fetchEntities();
      setLoading(false);
      Toaster({
        type: "success",
        msg: "Successfully created.",
      })
    } catch (error) {
      setLoading(false);
      Toaster({
        type: "error",
        msg: "Failed to create.",
      })
   }
  }

  const deleteEntityData = async (entityId: number) => {
    setLoading(true);
    try {
      await deleteEntity(entityId);
      await fetchEntities();
      setLoading(false);
      Toaster({
        type: "success",
        msg: "Successfully deleted.",
      })
    } catch (error) {
      setLoading(false);
      Toaster({
        type: "error",
        msg: "Failed to delete.",
      })
   }
  }

  return {
    resources: memoizedResources,
    loading,
    fetchEntities,
    updateEntityData,
    createEntityData,
    deleteEntityData
  }
}

