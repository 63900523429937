import { createApiInstance } from "api/config";

const api = createApiInstance()

// Promo Code
// Grape: api/futurelab/v1/promo_codes.rb
export const validatePromoCode = async (promoCode: string ) => {
  return await api.post(`/promo_codes/validate`,
    { promo_code: promoCode }
  );
};