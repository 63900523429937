import React from "react";
import { DayPickerSingleDateController } from "react-dates";
import moment from "moment";

const DateStep = ({
  date,
  setDate,
  focused,
  setFocused,
  available_dates,
  daySize,
}) => {
  return (
    <>
      <DayPickerSingleDateController
        initialVisibleMonth={() => moment(date)}
        date={date}
        onDateChange={(date) => {
          setDate(date);
        }}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        numberOfMonths={1}
        noBorder
        hideKeyboardShortcutsPanel
        daySize={daySize}
        isOutsideRange={(day) =>
          !available_dates.includes(day.format("DD/MM/YYYY"))
        }
      />
    </>
  );
};

export default DateStep;
