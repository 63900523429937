import React from "react";

import GenericMsgLoader from "components/shared/GenericMsgLoader";
import AgendaItem from "./AgendaItem";

const AgendaList = ({
  agendas,
  loading,
  onEdit,
  handleDeleteAgenda,
  handlePublishAgenda,
}) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-flex-col">
      {loading ? (
        <GenericMsgLoader content="loading" className="tw-pt-8" />
      ) : agendas.length > 0 ? (
        agendas.map((agenda) => (
          <AgendaItem
            key={agenda.id}
            agenda={agenda}
            onEdit={onEdit}
            handleDeleteAgenda={handleDeleteAgenda}
            handlePublishAgenda={handlePublishAgenda}
          />
        ))
      ) : (
        <div className="tw-pt-8">
          No currently have no agendas. Start by creating a new agenda.
        </div>
      )}
    </div>
  );
};

export default AgendaList;
