import React from "react";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "components/shared/modal/BaseModal";
import { Button, Modal } from "rsuite";
import AgendaForm from "../../forms/agenda/AgendaForm";
import AgendaFormSubmitButton from "./AgendaFormSubmit";
import AgendaFormProvider from "../../forms/agenda/context/agendaFormContext";

export const PortalAgendaModal = ({
  openModal,
  setOpenModal,
  handleUpdateAgenda,
  handleCreateAgenda,
  agenda,
}) => {
  return (
    <AgendaFormProvider agenda={agenda}>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <ModalHeader>{agenda ? "Edit Agenda" : "New Agenda"}</ModalHeader>
        <ModalBody>
          <AgendaForm />
        </ModalBody>
        <ModalFooter className="tw-flex tw-justify-end tw-pt-4">
          <Button appearance="ghost" onClick={() => setOpenModal(false)}>
            Cancel
          </Button>
          <AgendaFormSubmitButton
            setOpenModal={setOpenModal}
            agenda={agenda}
            handleUpdateAgenda={handleUpdateAgenda}
            handleCreateAgenda={handleCreateAgenda}
          />
        </ModalFooter>
      </Modal>
    </AgendaFormProvider>
  );
};

export default PortalAgendaModal;
