// React
import React from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import MentorActionCard from "../mentor/component/shared/MentorActionCard";
import portal from "../../../util/portal";
import { PlatformType, UserType } from "../../../contexts/mentor_profile";
import MenteeActionCard from "../mentee/component/portal/MenteeActionCard";

// UI
import { FaChevronDown } from "react-icons/fa";
import { Drawer } from "rsuite";

const ActionDrawer = ({
  convertText,
  openBookingDrawer,
  setOpenBookingDrawer,
  user,
}: {
  convertText: any;
  callBack?: any;
  openBookingDrawer: any;
  setOpenBookingDrawer: any;
  user: any;
}) => {
  const { type } = useOutletContext<any>();

  const height = type === PlatformType.FutureLab ? 310 : 240;

  return (
    <>
      <Drawer
        placement="bottom"
        open={openBookingDrawer}
        closeButton={false}
        onClose={() => setOpenBookingDrawer(false)}
        dialogClassName="tw-rounded-t-md"
        style={{ height }}
        backdrop={false}
      >
        <div className="tw-p-6 sm:tw-p-0 sm:tw-mt-6">
          <div className="tw-text-center">
            <a onClick={() => setOpenBookingDrawer(false)}>
              <FaChevronDown className="tw-text-lg" />
            </a>
          </div>
          {user.user_type === UserType.Mentor ? (
            <MentorActionCard
              bordered={false}
              daySize={35}
              convertText={convertText}
              callBack={() => setOpenBookingDrawer(false)}
            />
          ) : (
            <MenteeActionCard bordered={false} convertText={convertText} />
          )}
        </div>
      </Drawer>
    </>
  );
};

export default portal(ActionDrawer);
