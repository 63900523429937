import React from "react";

import { Col, FlexboxGrid } from "rsuite";
import PortalDesignForm from "./form/PortalDesignForm";

// FUT-1506: Initial PortalSetup was design to be multistep form.
// Check File History for earlier implementation as reference.

const PortalSetupContent = ({ enterpriseId }) => {
  return (
    <>
      <FlexboxGrid justify="center" className="tw-mt-10 tw-relative">
        <FlexboxGrid.Item as={Col} colspan={24} lg={18}>
          <FlexboxGrid.Item as={Col} colspan={24} lg={6}>
            <h5 className="tw-font-bold">Create your program</h5>

            <p className="montserrat">
              Design impactful mentorship programs with ease.
            </p>
          </FlexboxGrid.Item>

          {/* Main Content */}
          <FlexboxGrid.Item
            as={Col}
            colspan={24}
            lg={14}
            className="tw-relative tw-px-10"
          >
            <PortalDesignForm enterpriseId={enterpriseId} />
          </FlexboxGrid.Item>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default PortalSetupContent;
