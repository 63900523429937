import React from "react";

import {
  FormDatePicker,
  FormInputText,
} from "components/form/default/FormInputs";
import { Divider, Form } from "rsuite";

import { useFormContext } from "react-hook-form";

const AgendaForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Form>
      <Form.Group className="tw-w-1/2">
        {/* Program Start Time */}
        <FormDatePicker
          name="start_time"
          label={"Start Date and Time"}
          control={control}
          errors={errors}
          showMeridian
          format={"dd MMM yyyy - h:mm a"}
          placeholder={"End Date and Time"}
          placement="autoVerticalStart"
        />
      </Form.Group>
      <Form.Group className="tw-w-1/2">
        {/* Program End Time */}
        <FormDatePicker
          name="end_time"
          label={"End Date and Time"}
          control={control}
          errors={errors}
          showMeridian
          format={"dd MMM yyyy - h:mm a"}
          placeholder={"End Date and Time"}
          placement="autoVerticalStart"
        />
      </Form.Group>

      <Form.Group>
        <FormInputText
          name="title"
          label={"Title"}
          placeholder={"Agenda title"}
          control={control}
          errors={errors}
        />
      </Form.Group>
      <Form.Group>
        <FormInputText
          name="description"
          label={"Description"}
          placeholder={"Agenda description"}
          as="textarea"
          rows={5}
          control={control}
          errors={errors}
        />
      </Form.Group>
      <Divider />
      <Form.Group>
        <FormInputText
          name="link"
          label={"Link URL"}
          placeholder={"https://example.com"}
          control={control}
          errors={errors}
        />
      </Form.Group>
      <Form.Group>
        <FormInputText
          name="link_name"
          label={"Link name"}
          placeholder={"e.g. Click here"}
          control={control}
          errors={errors}
        />
      </Form.Group>
    </Form>
  );
};

export default AgendaForm;
