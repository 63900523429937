import React, { createContext, useState, useContext, useEffect } from "react";
import { Message } from "rsuite";
import GenericMsgLoader from "../components/shared/GenericMsgLoader";
import { getEnterprise, getProgram } from "api/portal/programApi";

const PortalContext = createContext(undefined);

export const PortalProvider = ({
  children,
  programSlug = null,
  enterpriseSlug = null,
}) => {
  const [enterprise, setEnterprise] = useState();
  const [program, setProgram] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, [programSlug, enterpriseSlug]);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      if (programSlug) {
        const programResponse = await getProgram(programSlug);
        setProgram(programResponse.data);
      }

      if (enterpriseSlug) {
        const enterpriseResponse = await getEnterprise(enterpriseSlug);
        setEnterprise(enterpriseResponse.data);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PortalContext.Provider
      value={{
        enterprise,
        setEnterprise,
        program,
        setProgram,
        fetchData,
        loading,
        error,
      }}
    >
      {children}
    </PortalContext.Provider>
  );
};

export const usePortal = () => useContext(PortalContext);

export function withPortal(WrappedComponent) {
  const Component = (props) => {
    const { enterprise, program, error } = usePortal();

    if (!program && !enterprise)
      return <GenericMsgLoader center content="loading" />;
    if (error) return <Message type="error" header={error} />;

    return (
      <WrappedComponent enterprise={enterprise} program={program} {...props} />
    );
  };

  return Component;
}
