import { IMentorContext } from "contexts/mentor_profile";
import React from "react";
import { useOutletContext } from "react-router-dom";
import { Steps } from "rsuite";
import translateText from "util/translateText";

const StepsList = ({ convertText, currentStep }) => {

  return (
    <Steps current={currentStep} small>
      <Steps.Item title={translateText(convertText, "Date")} />
      <Steps.Item title={translateText(convertText, "Time")} />
      <Steps.Item title={translateText(convertText, "Details")} />
    </Steps>
  );
};

export default StepsList;
