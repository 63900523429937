// React
import React from "react";

// FutureLab
import UserCards from "../dashboard/users/UserCards";
import GenericMsgLoader from "components/shared/GenericMsgLoader";

// UI
import { Stack } from "rsuite";

const SearchResult = (props) => {
  const {
    mentors,
    currentUser,
    loading,
    intersectionRef,
    setMentors,
    program,
  } = props;

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      {!mentors.length && !loading ? (
        <div className="tw-flex tw-justify-center tw-items-center tw-h-60">
          <div className="tw-max-w-md tw-text-center">
            <h6 className="tw-text-lg">
              Whoops, we couldn’t find that mentor!
            </h6>
            <p className="tw-text-center">
              How about trying a different keyword to help us out?
            </p>
          </div>
        </div>
      ) : (
        <Stack
          className="tw-overflow-x-auto no-scrollbar"
          wrap
          justifyContent="center"
          alignItems="stretch"
        >
          {mentors.map((mentor, i) => (
            <UserCards
              program={program}
              user={mentor}
              setUsers={setMentors}
              currentUser={currentUser}
              ref={i === mentors.length - 1 ? intersectionRef : null}
              key={mentor.id}
              location={
                program ? "Program Discovery Page" : "FL Discovery Page"
              }
            />
          ))}
        </Stack>
      )}
      {loading && (
        <GenericMsgLoader
          size="md"
          style={{ zIndex: 9999 }}
          center
          vertical
          backdrop
          content="loading"
        />
      )}
    </div>
  );
};

export default SearchResult;
