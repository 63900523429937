import React, { useEffect } from "react";
import { Col, FlexboxGrid, RadioTile, RadioTileGroup } from "rsuite";

const TimeStep = ({ date, time, setTime, timeslots }) => {
  useEffect(() => {
    const formattedDate = date.format("DD/MM/YYYY");
    if (!time && timeslots[formattedDate]?.length > 0) {
      setTime(timeslots[formattedDate][0]);
    }
  }, [timeslots, date, time, setTime]);

  return (
    <div>
      <h6 className="tw-mb-9 tw-text-xl">
        {date.format("dddd, DD MMMM YYYY")}
      </h6>
      <FlexboxGrid>
        <FlexboxGrid.Item as={Col} colspan={24} xs={24} lg={24}>
          <RadioTileGroup
            className="tw-grid sm:tw-grid-cols-2 tw-grid-cols-3 tw-gap-2"
            value={time}
          >
            {timeslots[date.format("DD/MM/YYYY")]?.map((slotTime, index) => (
              <RadioTile
                value={slotTime}
                key={index}
                className={`${
                  time === slotTime ? "tw-font-bold" : ""
                } tw-colspan-1`}
                checked={time === slotTime}
                onClick={() => setTime(slotTime)}
              >
                <span className="tw-text-base">{slotTime}</span>
              </RadioTile>
            ))}
          </RadioTileGroup>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  );
};

export default TimeStep;
