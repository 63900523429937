import { ErrorMessage } from "@hookform/error-message";
import FieldError from "components/form/FieldError";
import React from "react";
import { Controller } from "react-hook-form";
import { Form, Uploader } from "rsuite";

const FormFileUploader = ({ name, control, errors, setError }) => {
  return (
    <>
      <Form.ControlLabel>Upload File</Form.ControlLabel>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <>
            <Uploader
              {...field}
              action=""
              // TODO: set Valid file types
              // accept=".pdf"
              draggable
              multiple={false}
              renderFileInfo={(file, _) => (
                <span className="tw-text-sm">{file.name}</span>
              )}
              onChange={(value) => {
                const firstFile = value.length > 0 ? value[0]?.blobFile : null;
                field.onChange(firstFile);
              }}
              shouldUpload={(file) => {
                const isWithinSizeLimit =
                  file.blobFile.size / 1024 / 1024 <= 10;
                if (!isWithinSizeLimit) {
                  setError(name, {
                    type: "manual",
                    message: "File exceeds the 10MB size limit.",
                  });
                }
                return isWithinSizeLimit;
              }}
              onRemove={() => {
                field.onChange(null);
              }}
            >
              <div
                style={{
                  height: 100,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 5,
                }}
              >
                <span>Click or Drag files to this area to upload</span>
              </div>
            </Uploader>
            <ErrorMessage errors={errors} name={name} as={FieldError} />
          </>
        )}
      />
    </>
  );
};

export default FormFileUploader;
