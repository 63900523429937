import React from "react";

import { Divider, Message } from "rsuite";
import { usePortal } from "contexts/portal";

import DashboardNotificationsForm from "../../forms/dashboard_notifications/DashboardNotificationsForm";

const DashboardNotifications = () => {
  const { program } = usePortal();

  return (
    <div>
      <h6>Dashboard Notification Text</h6>
      <p>
        Set the message users will see upon entering the dashboard. Only one
        message can be up at anytime.
      </p>

      {program?.dashboard_alert && (
        <>
          <span className="tw-text-xs">Current active message</span>
          <Message className="tw-mt-2">{program?.dashboard_alert}</Message>
          <Divider />
        </>
      )}
      <DashboardNotificationsForm />
    </div>
  );
};

export default DashboardNotifications;
