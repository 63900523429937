import React, { useState } from "react";
import { PortalApi } from "../../../../../util/portal_api";
import { usePortal } from "../../../../../contexts/portal";
import { useFormContext } from "react-hook-form";
import Toaster from "../../../../shared/Toaster";
import { Button } from "rsuite";

const FormSubmitButton = () => {
  const { program, setProgram } = usePortal();
  const { handleSubmit } = useFormContext();
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    if (program) {
      data.program_id = program.id;
    }

    const form = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (typeof value === "object" && !(value instanceof File)) {
          Object.entries(value).forEach(([subKey, subValue]) => {
            if (subValue !== undefined && subValue !== null) {
              form.append(`${key}[${subKey}]`, subValue);
            }
          });
        } else {
          form.append(key, value as any);
        }
      }
    });

    setLoading(true);
    PortalApi({
      method: "post",
      path: "/programs",
      options: form,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      success: ({ data }) => {
        setLoading(false);
        setProgram(data);
        Toaster({
          type: "success",
          msg: "Successfully saved.",
        });
      },
      error: ({ data }) => {
        console.log(data);
        setLoading(false);

        Toaster({
          type: "error",
          msg: "Oops, something went wrong.",
        });
      },
    });
  };

  return (
    <>
      <Button
        appearance="primary"
        onClick={handleSubmit(onSubmit)}
        loading={loading}
        className="tw-mb-6"
      >
        Save
      </Button>
    </>
  );
};

export default FormSubmitButton;
