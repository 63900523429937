import React from "react";
import ResourcesTable from "./ResourcesTable";
import AddResourceHandler from "./AddResourceHandler";
import ResourceEditorModal from "./ResourceEditorModal";
import ResourceFormProvider from "./forms/context/resourceFormContext";
import { ResourceProvider } from "./context/resourceContext";

const Resources = () => {
  return (
    <ResourceProvider>
      <ResourceFormProvider>
        <div className="tw-flex tw-justify-end">
          <AddResourceHandler />
        </div>
        <ResourcesTable />

        <ResourceEditorModal />
      </ResourceFormProvider>
    </ResourceProvider>
  );
};

export default Resources;
