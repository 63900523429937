import { useState, useEffect, useCallback } from "react";
import { getAllAgendas, deleteAgenda, publishAgenda, updateAgenda, createAgenda } from "api/portal/programApi";
import { usePortal } from "contexts/portal";
import Toaster from "components/shared/Toaster";

export const useAgenda = () => {
  const [agendas, setAgendas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAgenda, setSelectedAgenda] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { program } = usePortal();

  const fetchAgendas = useCallback(async () => {
    if (!program) return;

    setLoading(true);
    try {
      const { data } = await getAllAgendas(program.id);
      setAgendas(data);
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Fetch error:", error);
        Toaster({
          type: "error",
          msg: "Failed to fetch data. Please try again.",
        })
      }
    } finally {
      setLoading(false);
    }
  }, [program]);

  useEffect(() => {
    if (!program) return;
    const controller = new AbortController();
    fetchAgendas();

    return () => {
      controller.abort();
    };
  }, [program]);

    const handleUpdateAgenda = async (updatedAgenda) => {
    try {
      await updateAgenda(updatedAgenda.id, updatedAgenda);
      setAgendas((prevAgendas) =>
        prevAgendas.map((agenda) =>
          agenda.id === updatedAgenda.id ? updatedAgenda : agenda
        )
      );
      Toaster({
        type: "success",
        msg: "Agenda updated successfully",
      })
    } catch (error) {
      console.error("Update error:", error);
      Toaster({
        type: "error",
        msg: "Failed to update agenda",
      })
    }
    };

  const handleCreateAgenda = async (programId) => {
    try {
      const { data: newAgenda } = await createAgenda(programId);
      setAgendas((prevAgendas) => [...prevAgendas, newAgenda]);
      Toaster({
        type: "success",
        msg: "Agenda created successfully",
      })
    } catch (error) {
      console.error("Create error:", error);
      Toaster({
        type: "error",
        msg: "Failed to create agenda",
      })
    }
  }

  const handleDeleteAgenda = async (agendaId) => {
    try {
      await deleteAgenda(agendaId);  // Assuming an API call to delete the agenda
      setAgendas((prevAgendas) =>
        prevAgendas.filter((agenda) => agenda.id !== agendaId)
      );
      Toaster({
        type: "success",
        msg: "Agenda deleted successfully",
      })
    } catch (error) {
      console.error("Delete error:", error);
      Toaster({
        type: "error",
        msg: "Failed to delete agenda",
      })
    }
  };

  const handlePublishAgenda = async (agendaId) => {
    try {
      const { data: publishedAgenda } = await publishAgenda(agendaId);
      setAgendas((prevAgendas) =>
        prevAgendas.map((agenda) =>
          agenda.id === publishedAgenda.id ? publishedAgenda : agenda
        )
      );
      Toaster({
        type: "success",
        msg: `Agenda ${publishedAgenda.published ? "published" : "unpublished"} successfully`,
      })
    } catch (error) {
      console.error("Update error:", error);
      Toaster({
        type: "error",
        msg: "Failed to update agenda",
      })
    }
  };

  const handleEdit = (agenda) => {
    setSelectedAgenda(agenda);
    setOpenModal(true);
  };

    const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedAgenda(null);
  };

  const handleOpenModal = () => {
    setSelectedAgenda(null);
    setOpenModal(true);
  };

  return {
    agendas,
    loading,
    selectedAgenda,
    openModal,
    handleEdit,
    handleOpenModal,
    handleCloseModal,
    handleCreateAgenda,
    handleDeleteAgenda,
    handleUpdateAgenda,
    handlePublishAgenda,
    fetchAgendas,
  };
};
