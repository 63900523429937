// AddResourceHandler.js
import React from "react";
import { Button, Dropdown } from "rsuite";
import { useResource } from "./context/resourceContext";

const renderButton = (props, ref) => (
  <Button appearance="primary" {...props} ref={ref}>
    Add
  </Button>
);

const AddResourceHandler = () => {
  const { setOpenModal, setIsFile } = useResource();

  const handleSelect = (eventKey) => {
    setOpenModal(true);
    setIsFile(eventKey === 2);
  };

  return (
    <Dropdown
      renderToggle={renderButton}
      placement="bottomEnd"
      onSelect={handleSelect}
    >
      <Dropdown.Item eventKey={1}>
        <span className="montserrat">New Link</span>
      </Dropdown.Item>
      <Dropdown.Item eventKey={2}>
        <span className="montserrat">New File</span>
      </Dropdown.Item>
    </Dropdown>
  );
};

export default AddResourceHandler;
