import React from "react";
import { Button, Table } from "rsuite";
import Toaster from "components/shared/Toaster";
import { useResource } from "./context/resourceContext";
import { MdOutlineFilePresent, MdOutlineLink } from "react-icons/md";

const ResourcesTable = () => {
  const { Column, HeaderCell, Cell } = Table;
  const { resources, loading, setOpenModal, setResource, deleteEntityData } =
    useResource();

  const handleDelete = async (resourceId) => {
    try {
      await deleteEntityData(resourceId);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (resource) => {
    setOpenModal(true);
    setResource(resource);
  };

  return (
    <Table
      data={resources}
      loading={loading}
      autoHeight
      rowClassName="tw-text-sm tw-w-full"
      rowHeight={66}
    >
      <Column
        align="left"
        verticalAlign="middle"
        minWidth={200}
        flexGrow={2}
        fullText
      >
        <HeaderCell>Title</HeaderCell>
        <Cell>
          {(rowData) => {
            const { title, category, url, file, file_name } = rowData;
            const isLink = category === "Link";

            return (
              <div className="tw-flex tw-flex-col tw-text-sm tw-justify-start">
                <span className="tw-font-medium">{title}</span>
                <a
                  className="tw-flex tw-items-center"
                  target="_blank"
                  href={isLink ? url : file}
                >
                  {isLink ? (
                    <>
                      <MdOutlineLink size={16} className="tw-mr-1" />
                      {url}
                    </>
                  ) : (
                    <>
                      <MdOutlineFilePresent size={16} className="tw-mr-1" />
                      {file_name}
                    </>
                  )}
                </a>
              </div>
            );
          }}
        </Cell>
      </Column>
      <Column align="center" verticalAlign="middle" flexGrow={1}>
        <HeaderCell>Type</HeaderCell>
        <Cell dataKey="category" />
      </Column>
      <Column align="center" verticalAlign="middle" flexGrow={1}>
        <HeaderCell>Viewable by</HeaderCell>
        <Cell dataKey="user_type" />
      </Column>
      <Column align="center" verticalAlign="middle" flexGrow={1}>
        <HeaderCell>Uploaded</HeaderCell>
        <Cell dataKey="created_at" />
      </Column>
      <Column align="center" verticalAlign="middle" flexGrow={1}>
        <HeaderCell>Actions</HeaderCell>
        <Cell>
          {(rowData) => (
            <>
              <Button appearance="link" onClick={() => handleEdit(rowData)}>
                Edit
              </Button>
              <Button
                appearance="link"
                onClick={() => handleDelete(rowData.id)}
                style={{ color: "red" }}
              >
                Delete
              </Button>
            </>
          )}
        </Cell>
      </Column>
    </Table>
  );
};

export default ResourcesTable;
