import React from "react";
import {
  FormInputText,
  FormSelectPicker,
} from "components/form/default/FormInputs";
import { usePortal } from "contexts/portal";
import { useFormContext } from "react-hook-form";
import { Form } from "rsuite";

import FormFileUploader from "./FormFileUploader";

const ResourcesForm = ({ isFile }) => {
  const {
    control,
    formState: { errors },
    setError,
  } = useFormContext();

  const { program } = usePortal();

  const rolesData = program?.available_roles
    ? program.available_roles.map((role) => ({
        label: role.name,
        value: role.name,
      }))
    : [];

  return (
    <Form>
      <Form.Group>
        {isFile ? (
          <FormFileUploader
            name="file"
            control={control}
            errors={errors}
            setError={setError}
          />
        ) : (
          <FormInputText
            name="url"
            placeholder="URL"
            label="URL"
            control={control}
            errors={errors}
          />
        )}
      </Form.Group>
      <Form.Group>
        <FormInputText
          name="title"
          placeholder="Title"
          label="Title"
          control={control}
          errors={errors}
        />
      </Form.Group>
      <Form.Group>
        <FormInputText
          name="description"
          placeholder="Description"
          label="Description (optional)"
          as="textarea"
          rows={5}
          control={control}
          errors={errors}
        />
      </Form.Group>
      <Form.Group>
        <FormSelectPicker
          name="user_type"
          control={control}
          label="Viewable by"
          placeholder="Select a user type"
          data={[{ label: "All Users", value: "All" }, ...rolesData]}
          cleanable={false}
          placement="autoVertical"
          errors={errors}
          block
        />
      </Form.Group>
    </Form>
  );
};

export default ResourcesForm;
