import React, { useState } from "react";

import { Button, Divider, Toggle } from "rsuite";
import { MdOutlineAccessTime, MdOutlineCalendarMonth } from "react-icons/md";
import { FaPaperclip } from "react-icons/fa";
import { formatDateTime, isOnSameDay } from "util/dateTimeFormatter";

import DeleteConfirmationModal from "./DeleteConfirmationModal";

const AgendaItem = ({
  agenda,
  onEdit,
  handleDeleteAgenda,
  handlePublishAgenda,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div className="tw-p-6 tw-mb-4 tw-border-solid tw-border tw-w-full tw-border-slate-200 tw-rounded-lg tw-shrink-0">
      <h6>{agenda.title}</h6>
      <div className="tw-text-sm">
        <span className="tw-gap-2 tw-pr-6 tw-inline-flex tw-items-center">
          <MdOutlineCalendarMonth size={16} />
          {isOnSameDay(agenda.start_time, agenda.end_time)
            ? formatDateTime(agenda.start_time, "Do MMMM YYYY")
            : `${formatDateTime(
                agenda.start_time,
                "Do MMMM YYYY"
              )} - ${formatDateTime(agenda.end_time, "Do MMMM YYYY")}`}
        </span>
        <span className="tw-gap-2 tw-inline-flex tw-items-center">
          <MdOutlineAccessTime size={16} />
          {`${formatDateTime(agenda.start_time, "h:mm A")} - ${formatDateTime(
            agenda.end_time,
            "h:mm A"
          )}`}
        </span>
      </div>
      <Divider />

      <p className="montserrat">{agenda.description}</p>
      {agenda.link && (
        <Button
          appearance="link"
          href={agenda.link}
          target="_blank"
          startIcon={<FaPaperclip size={16} />}
          className="pl-0"
        >
          {agenda.link_name}
        </Button>
      )}
      <Divider />
      <div className="tw-flex tw-items-center tw-justify-between">
        <div>
          <span className="tw-text-sm tw-mr-1">Published</span>
          <Toggle
            checked={agenda.published}
            checkedChildren="Yes"
            unCheckedChildren="No"
            loading={loading}
            onChange={() => handlePublishAgenda(agenda.id)}
          />
        </div>
        <div className="tw-flex tw-gap-2">
          <Button appearance="ghost" onClick={() => onEdit(agenda)}>
            Edit
          </Button>
          <Button
            appearance="ghost"
            color="red"
            onClick={() => setOpenModal(true)}
          >
            Delete
          </Button>
        </div>
      </div>
      <DeleteConfirmationModal
        agenda={agenda}
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleDeleteAgenda={handleDeleteAgenda}
      />
    </div>
  );
};

export default AgendaItem;
