import React from "react";
import { withContainer } from "../components";
import { FormWrapper } from "../components";
import {
  enterpriseSchema,
  EnterpriseRegistrationForm,
} from "../components/forms/RegistrationForm";

// 3rd party
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToaster, Message } from "rsuite";
import * as yup from "yup";
import { createEnterprise } from "../api";
import { useEnterprise } from "contexts/enterpriseContext";

const schema = yup.object().shape({
  enterprise: enterpriseSchema,
});

const CreateEnterprise = () => {
  const toaster = useToaster();
  const { getCurrentAdmin } = useEnterprise();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const enterpriseData = data.enterprise;
    try {
      await createEnterprise(enterpriseData);
      toaster.push(
        <Message type="success">
          Enterprise account created successfully. Please check your email for
          confirmation.
        </Message>
      );
    } catch (error) {
      toaster.push(
        <Message type="error">
          Something went wrong. Please try again later.
        </Message>
      );
    } finally {
      getCurrentAdmin();
    }
  };

  const onErrors = (errors) => {
    console.log(errors);
  };

  React.useEffect(() => {
    toaster.push(
      <Message type="info">
        You dont have any enterprise yet. Please create one.
      </Message>
    );
  }, []);

  return (
    <FormWrapper
      footerText="Go back to"
      footerLink={{ title: "Program Selection", to: "/program-selection" }}
      title="Create Enterprise Account"
    >
      <form
        className="rs-form rs-form-vertical rs-form-fixed-width"
        onSubmit={handleSubmit(onSubmit, onErrors)}
      >
        <EnterpriseRegistrationForm control={control} errors={errors} />
        <div className="rs-form-group">
          <button
            type="submit"
            className="rs-btn rs-btn-primary rs-btn-block"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Creating..." : "Create"}
          </button>
        </div>
      </form>
    </FormWrapper>
  );
};

export default withContainer(CreateEnterprise);
