import React, { useState } from "react";
import { Button, Col, FlexboxGrid, Nav } from "rsuite";
import { usePortal } from "../../../../contexts/portal";

import BasicInfoForm from "../forms/settings/BasicInfoForm";
import NotificationsForm from "../forms/settings/NotificationsForm";
import PairingMethodsForm from "../forms/settings/PairingMethodsForm";
import PrivacyForm from "../forms/settings/PrivacyForm";
import SettingsFormProvider from "../forms/settings/context/settingsFormContext";

const PortalSettings = () => {
  const { program } = usePortal();
  const [activeKey, setActiveKey] = useState("1");

  const onSelect = (key) => {
    setActiveKey(key);
  };

  const renderForm = (key) => {
    const form = {
      "1": <BasicInfoForm />,
      "2": <PairingMethodsForm />,
      "3": <PrivacyForm />,
      "4": <NotificationsForm />,
    };

    return form[key];
  };

  return (
    <SettingsFormProvider>
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} lg={14}>
          <h1 className="tw-text-xl">Settings</h1>
          <p className="montserrat">
            Adjust portal settings and preferences here for {program?.name}. You
            can also manage members and roles.
          </p>

          <Nav
            activeKey={activeKey}
            onSelect={onSelect}
            appearance="subtle"
            justified
            className="tw-mb-6"
          >
            <Nav.Item className="tw-justify-center" eventKey="1">
              Basic Info
            </Nav.Item>
            <Nav.Item className="tw-justify-center" eventKey="2">
              Pairing Methods
            </Nav.Item>
            <Nav.Item className="tw-justify-center" eventKey="3">
              Privacy
            </Nav.Item>
            <Nav.Item className="tw-justify-center" eventKey="4">
              Notifications
            </Nav.Item>
          </Nav>

          {/*TODO: Button to save or onChange */}
          {renderForm(activeKey)}
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </SettingsFormProvider>
  );
};

export default PortalSettings;
