import React from "react";
import AppContainer from "./AppContainer";

import PortalSetupContent from "./portal/setup/PortalSetupContent";
import { useEnterprise } from "../contexts/enterpriseContext";
import { Col, FlexboxGrid } from "rsuite";

const PortalSetup = () => {
  const { state } = useEnterprise();

  // FUT-1589: Enterprises is an Array, current setup is only one enterprise per account.
  // Suggest to add to state - current enterprise that is being accessed, if multiple enterprise
  // accounts per user is introduced.
  const enterpriseId = state?.enterprises?.[0]?.id || null;

  if (!state && !enterpriseId) {
    return (
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          lg={6}
          sm={24}
          className="tw-text-center tw-mt-5"
        >
          You do not have the right credentials to access this page. Please
          contact the FutureLab team for further assistance.
        </FlexboxGrid.Item>
      </FlexboxGrid>
    );
  }

  return (
    <AppContainer>
      <PortalSetupContent enterpriseId={enterpriseId} />
    </AppContainer>
  );
};

export default PortalSetup;
