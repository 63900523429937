import axios, { AxiosInstance } from "axios";
import Cookies from "js-cookie";

const urls = {
  default: {
    development: "http://lvh.me:3000/api/v1",
    production: "https://api.futurelab.my/api/v1",
    staging: "https://futurelab.global/api/v1",
  },
  portal: {
    development: "http://lvh.me:3000/api/portal/v1",
    production: "https://api.futurelab.my/api/portal/v1",
    staging: "https://futurelab.global/api/portal/v1",
  },
  enterprise: {
    development: "http://lvh.me:3000/api/enterprise/v1",
    production: "https://api.futurelab.my/api/enterprise/v1",
    staging: "https://futurelab.global/api/enterprise/v1",
  },
  rails: {
    development: "/",
    production: "/",
    staging: "/",
  }
};

const getRootUrl = (context: keyof typeof urls = 'default'): string => {
  const environment = process.env.RAILS_ENV || 'development';
  const contextUrls = urls[context];
  return contextUrls[environment];
};

const createApiInstance = (context: keyof typeof urls = 'default'): AxiosInstance => {
  const baseUrl = getRootUrl(context);

  const api = axios.create({ baseURL: baseUrl, withCredentials: false });

  api.interceptors.request.use((config) => {
    const token =
      Cookies.get("pwa_token") ||
      Cookies.get(`pwa_${process.env.RAILS_ENV}_session`);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  return api;
};

export { urls, getRootUrl, createApiInstance };
