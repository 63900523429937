import React from "react";
import { Avatar, Divider, Panel, Tag } from "rsuite";
import { MdOutlineCalendarMonth, MdOutlineSchedule } from "react-icons/md";

const SummaryStep = ({ mentor, date, time, goals, getValues }) => {
  const learningObjectives = getValues("learning_objectives");
  const filteredObjectives =
    goals?.filter((goal) => learningObjectives.includes(goal.name)) || [];
  const topic = getValues("topic");

  return (
    <>
      <h6>Booking Summary</h6>
      <Panel bordered className="tw-w-full">
        <p className="tw-text-sm tw-font-medium tw-mb-1">Your mentor</p>
        <div className="tw-flex tw-gap-4">
          <Avatar
            src={mentor?.profile_photo?.url}
            alt={mentor.firstname.charAt(0)}
            size="lg"
          />

          <div className="tw-flex tw-flex-col">
            <span className="tw-font-bold">
              {mentor.firstname} {mentor.lastname}
            </span>
            <span className="tw-text-sm tw-flex tw-items-center">
              <MdOutlineCalendarMonth size={16} className="tw-mr-1" />
              {date.format("dddd, DD MMMM YYYY")}
            </span>
            <span className="tw-text-sm tw-flex tw-items-center">
              <MdOutlineSchedule size={16} className="tw-mr-1" />
              {time}
            </span>
          </div>
        </div>

        <Divider />

        <span className="tw-text-sm tw-font-medium">
          What do you need help with?
        </span>
        <p className="tw-text-sm">{topic}</p>

        <span className="tw-text-sm tw-font-medium">Topics of discussion</span>
        <div className="tw-mt-1">
          {filteredObjectives.map((objective, idx) => (
            <Tag key={idx}>{objective.name}</Tag>
          ))}
        </div>
      </Panel>
    </>
  );
};

export default SummaryStep;
