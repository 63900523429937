import React, { useState } from "react";

import { Col, FlexboxGrid, Nav } from "rsuite";
import DashboardNotifications from "../components/resources/DashboardNotifications";
import Resources from "../components/resources/Resources";

const renderForm = (key) => {
  const form = {
    "1": <DashboardNotifications />,
    "2": <Resources />,
  };

  return form[key];
};

const PortalResources = () => {
  const [activeKey, setActiveKey] = useState("1");

  return (
    <FlexboxGrid justify="center">
      <FlexboxGrid.Item as={Col} colspan={24} md={18} lg={24}>
        <h1 className="tw-text-xl">Resources</h1>
        <p>Setup what users see on the dashboard</p>
        <Nav
          className="tw-mb-6"
          appearance="subtle"
          activeKey={activeKey}
          onSelect={setActiveKey}
        >
          <Nav.Item eventKey={"1"}>Notifications</Nav.Item>
          <Nav.Item eventKey={"2"}>Resources</Nav.Item>
        </Nav>

        {renderForm(activeKey)}

        {/* Resources Table */}
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default PortalResources;
